// PAGE CONTENT
export enum SectionType {
  TEACHERS = 'TEACHERS',
  HEADING = 'HEADING',
  TEXT_LIST = 'TEXT_LIST',
  COLUMNS = 'COLUMNS',
  COLUMNS_VIDEO = 'COLUMNS_VIDEO',
  COLUMNS_IMAGE = 'COLUMNS_IMAGE',
  TEXT = 'TEXT',
  TWO_COLUMNS_TEXT = 'TWO_COLUMNS_TEXT',
  TWO_COLUMNS_VIMEO = 'TWO_COLUMNS_VIMEO',
  VIMEO = 'VIMEO',
  YOUTUBE = 'YOUTUBE',
  DIVIDER = 'DIVIDER',
  BUTTON = 'BUTTON',
  AUDIO = 'AUDIO',
  TEST_ONE_OPTION = 'TEST_ONE_OPTION',
  TEST_TRUE_FALSE = 'TEST_TRUE_FALSE',
  TEST_TEXT_ANSWER = 'TEST_TEXT_ANSWER',
  TEST_INPUT_ANSWER = 'TEST_INPUT_ANSWER',
  TEST_BUTTONS = 'TEST_BUTTONS',
  TEST_ADD_WORDS = 'TEST_ADD_WORDS',
  TEST_ADD_LETTERS = 'TEST_ADD_LETTERS',
  TEST_SYNC_TRANSLATE = 'TEST_SYNC_TRANSLATE',
  SYNC_TRANSLATE = 'SYNC_TRANSLATE',
  WORD_CARD = 'WORD_CARD'
}

export type IAlignment = 'start' | 'center' | 'end'

// TESTS:

export type IAnswer = string | string[]

export type ITestResult = {
  userId: string
  entityId: string
  weekId: string
  dayId: string
  lessonId: string
  isComplete: boolean
  timestamp: number
  correctAnswersAmount: number
  questionsAmount: number
}

export type IExaminationAttempt = {
  id: string
  lessonId: string
  userId: string
  entityId: string
  attemptNum: number
  firstAttemptTimestamp: number
  timestamp: number
  finished?: boolean
}

export type ISessionAnswer = {
  answer: IAnswer
  correctValue?: string
  isCorrect: boolean
}

export type ITestOption = {
  id: string
  value: string
}
export type ISubmitAnswerRequest = {
  entityId: string
  weekId: string
  dayId: string
  lessonId: string
  sessionAnswers: Record<string, ISessionAnswer>
}

export type ISubmitAnswerResponse = {
  success: boolean
  message?: string
}

export type ISyncTranslate = {
  task: string
  translate: string
  matching: number[]
}

export type ITestContent = {
  options?: ITestOption[]
  text?: string
  highlightCorrectAnswer?: boolean
  answer?: IAnswer
  title?: string
  syncTranslate?: ISyncTranslate[]
}

// END: TESTS

// SECTIONS: START
export type ITextStyle = 'h1' | 'h2' | 'h3' | 'body' | 'small' | 'tag' | 'link'
export enum IPadding {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large'
}

type ISectionBase = {
  id: string
  type: SectionType
  widthCol: number
  paddingTop?: IPadding
}

export type IStyledText = {
  text: string
  textStyle: ITextStyle
}

export type ISectionHeading = ISectionBase & {
  type: SectionType.HEADING
  data: IStyledText[]
  alignment: IAlignment
}

export type ISectionTextList = ISectionBase & {
  type: SectionType.TEXT_LIST
  title: IStyledText
  data: IStyledText[]
  alignment: IAlignment
}

export type ISectionCard = {
  hideTitle?: boolean
  title: IStyledText
  data: IStyledText[]
}

export type ISectionColumns = ISectionBase & {
  type: SectionType.COLUMNS
  columns: ISectionCard[]
  showNumbers?: boolean
  numbersAlignment?: IAlignment
  columnsAmount?: number
  showCarousel?: boolean
}

export enum IVideoPlatform {
  VIMEO = 'vimeo',
  YOUTUBE = 'youtube'
}

export type IVideo = {
  platform: IVideoPlatform
  url: string
}

export type ISectionColumnsVideo = ISectionBase & {
  type: SectionType.COLUMNS_VIDEO
  columns: IVideo[]
  columnsAmount?: number
}

export type ISectionColumnsImage = ISectionBase & {
  type: SectionType.COLUMNS_IMAGE
  columns: string[]
  columnsAmount?: number
  showCarousel?: boolean
}

export type ISectionVimeo = ISectionBase & {
  type: SectionType.VIMEO
  title?: string
  textStyle?: ITextStyle
  titleAlignment?: IAlignment
  vimeoVideoId?: string
}

export type ISectionYoutube = ISectionBase & {
  type: SectionType.YOUTUBE
  title?: string
  textStyle?: ITextStyle
  titleAlignment?: IAlignment
  youtubeVideoId?: string
}

export type ISectionAudio = ISectionBase & {
  type: SectionType.AUDIO
  title?: string
  textStyle?: ITextStyle
  audio?: FileT
}

export type ISectionTestButtons = ISectionBase & {
  type: SectionType.TEST_BUTTONS
  htmlString?: string
  textStyle?: ITextStyle
  test?: ITestContent
}

export type ISectionTestTrueFalse = ISectionBase & {
  type: SectionType.TEST_TRUE_FALSE
  htmlString?: string
  textStyle?: ITextStyle
  test?: ITestContent
}

export type ISectionTestOneOption = ISectionBase & {
  type: SectionType.TEST_ONE_OPTION
  htmlString?: string
  textStyle?: ITextStyle
  test?: ITestContent
}

export type ISectionTestInputAnswer = ISectionBase & {
  type: SectionType.TEST_INPUT_ANSWER
  htmlString?: string
  textStyle?: ITextStyle
  test?: ITestContent
}

export type ISectionTestTextAnswer = ISectionBase & {
  type: SectionType.TEST_TEXT_ANSWER
  htmlString?: string
  textStyle?: ITextStyle
  test?: ITestContent
}

export type ISectionTestAddWords = ISectionBase & {
  type: SectionType.TEST_ADD_WORDS
  test?: ITestContent
}

export type ISectionTestAddLetters = ISectionBase & {
  type: SectionType.TEST_ADD_LETTERS
  test?: ITestContent
}

export type ISectionTestSyncTranslate = ISectionBase & {
  type: SectionType.TEST_SYNC_TRANSLATE
  test?: ITestContent
}

export type ISectionText = ISectionBase & {
  type: SectionType.TEXT
  htmlString?: string
  textStyle?: ITextStyle
}

export type ISectionButton = ISectionBase & {
  type: SectionType.BUTTON
  button: {
    title: string
    url?: string
    alignment?: IAlignment
  }
}

export type ISectionTwoColumnsText = ISectionBase & {
  type: SectionType.TWO_COLUMNS_TEXT
  title?: string
  htmlString?: string
}

export type ISectionTwoColumnsVimeo = ISectionBase & {
  type: SectionType.TWO_COLUMNS_VIMEO
  title?: string
  vimeoVideoId?: string
}

export type ISectionDivider = ISectionBase & {
  type: SectionType.DIVIDER
}

export type ISectionTeachers = ISectionBase & {
  type: SectionType.TEACHERS
  teachers: string[]
}

export type ISyncTransalteParagraph = {
  left: string
  right: string
}

export type ISectionSyncTranslate = ISectionBase & {
  type: SectionType.SYNC_TRANSLATE
  paragraphs: ISyncTransalteParagraph[]
}

export type ISectionWordCard = ISectionBase & {
  type: SectionType.WORD_CARD
  cardId: string
}

export type ISection =
  | ISectionHeading
  | ISectionTextList
  | ISectionColumns
  | ISectionColumnsVideo
  | ISectionColumnsImage
  | ISectionVimeo
  | ISectionAudio
  | ISectionTestButtons
  | ISectionTestTrueFalse
  | ISectionTestOneOption
  | ISectionTestInputAnswer
  | ISectionTestTextAnswer
  | ISectionTestAddLetters
  | ISectionTestAddWords
  | ISectionTestSyncTranslate
  | ISectionYoutube
  | ISectionText
  | ISectionButton
  | ISectionTwoColumnsText
  | ISectionTwoColumnsVimeo
  | ISectionDivider
  | ISectionTeachers
  | ISectionSyncTranslate
  | ISectionWordCard
// SECTIONS: END

export type IPageContent = {
  sections: Record<string, ISection>
  sectionsOrder: string[]
}

export type IPartition = {
  id: string
  name: string
}

export type ILevel = {
  id: string
  name: string
}

export type ITeacher = {
  id: string
  createdAt: number
  name: string
  title?: string
  description?: string
  avatarUrl?: string
  partitions: string[]
  video?: IVideo
  image?: string
}

export interface IDeprecatedFields {
  date?: string
  description?: string
  description2?: string
  description3?: string
  description4?: string
  sLevel?: string
  days?: number[]
  schedule?: string
}

export type IDay = {
  id: string
  title: string
  lessons: string[]
  perc?: number
  disabled?: boolean
}

export type IWeek = {
  id: string
  title: string
  days: IDay[]
  perc?: number
  enabled?: boolean
}
export interface ICourse extends IDeprecatedFields {
  id: string
  createdAt: number
  title: string
  subTitle?: string
  invisible: boolean
  closed: boolean
  startDate?: number
  endDate?: number
  price: number
  oldPrice?: number
  priceEur?: number
  oldPriceEur?: number
  imageUrl?: string
  previewUrl?: string
  lessons: string[]
  vimeoVideoUrl?: string
  sections?: Record<string, ISection>
  sectionsOrder?: string[]
  partitionId?: string
  levelId?: string
  paymentLink?: string
  content?: IWeek[]
  tags?: string[]
  studentsLimit?: number
  studentsAmount?: number
  copyOf?: {
    entityId: string
    entityType: IEntityType
  }
}

export interface ISeminar extends ICourse {
  courseId?: string
  teacherId?: string
  partitionId?: string
  levelId?: string
  content?: IWeek[]
}

export interface IWebinar extends ICourse {}

export type IPlan = {
  id: string
  description?: string
  bulletPoints?: string[]
  courseId: string
  buttonTitle?: string
  buttonUrl?: string
}

export type ICoursesPack = Omit<
  ICourse,
  'copyOf' | 'paymentLink' | 'lessons' | 'previewUrl' | 'price' | 'oldPrice'
> & {
  plans: IPlan[]
}

export interface IConference {
  id: string
  createdAt: number
  title: string
  subTitle?: string
  lessons: string[]
  startDate?: number
  endDate?: number
  content?: IWeek[]
  copyOf?: {
    entityId: string
    entityType: IEntityType
  }
}

export enum ILessonType {
  LECTURE = 'lecture',
  TEST = 'test',
  EXAMINATION = 'examination',
  DICTIONARY = 'dictionary'
}

export type IExaminationMessage = {
  perc: number
  msg: string
}

export type IExaminationSettings = {
  duration: number
  attempts: number
  cooldown: number
  messages: IExaminationMessage[]
}

export type IDictionarySettings = {
  lang: string
}
export interface ILesson {
  id: string
  name: string
  description?: string
  homework?: string
  videoUrl?: string
  entityId: string
  sections?: Record<string, ISection>
  sectionsOrder?: string[]
  perc?: number
  type: ILessonType
  examination?: IExaminationSettings
  dictionary?: IDictionarySettings
}

export interface IFree extends ICourse {}

export type IEntity = IWebinar | IConference | ISeminar | ICourse | IFree

export enum IEntityType {
  WEBINARS = 'webinars',
  CONFERENCES = 'conferences',
  SEMINARS = 'seminars',
  COURSES = 'courses',
  LIBRARY = 'library',
  FREE = 'free',
  PAYMENT_LINKS = 'paymentLinks'
}
export interface IUserPayment {
  id: string
  createdAt: number
  entityId: string
  price: string
  transactionId?: string
  type: IEntityType
  userId: string
  name?: string
  email?: string
}

export interface IUserEntities {
  [IEntityType.COURSES]: Record<string, ICourse>
  [IEntityType.WEBINARS]: Record<string, IWebinar>
  [IEntityType.SEMINARS]: Record<string, ISeminar>
  [IEntityType.CONFERENCES]: Record<string, IConference>
  [IEntityType.LIBRARY]: Record<string, ICourse>
  [IEntityType.FREE]: Record<string, IFree>
}

export interface IUser {
  id: string
  name?: string
  email?: string
  phone?: string
  createdAt?: number
  telegram?: string
}

export type IPaymentLink = Omit<
  ICourse,
  | 'copyOf'
  | 'paymentLink'
  | 'lessons'
  | 'previewUrl'
  | 'oldPrice'
  | 'invisible'
  | 'closed'
>

// storage
export type FileT = {
  id: string
  name: string
  size?: number
  url?: string
  createdAt: number
  createdBy?: string
  contentType?: string
  thumbnailUrl?: string
  storagePath?: string
}

export type StorageFileT = {
  url: string
  size: number
  contentType?: string
}

export interface StorageT {
  saveFileObject: (
    fileObject: File | Blob | Uint8Array | ArrayBuffer,
    storagePath: string,
    onProgress?: (v: number) => void,
    isPrivate?: boolean
  ) => Promise<StorageFileT | undefined>
  createImageThumbnail: (file: any, thumbWidth?: number) => Promise<unknown>
  saveFile: (
    storagePath: string,
    fileUrl: string,
    onProgress?: (v: number) => void
  ) => Promise<StorageFileT | undefined>
}

export interface IItemInfo {
  title: string
  id: string
  type: IEntityType
  price: number
  paymentLink?: string
  successPath?: string
}

export interface ITransaction {
  id: string
  createdAt: number
  userId: string
  entityId: string
  entityType: IEntityType
  amount: string
  paid: boolean
}

export interface IAdmin {
  id: string
  email: string
}

export interface INotificationsSettings {
  email: string
  payments?: boolean
  feedback?: boolean
}
export interface IFeedback {
  id: string
  createdAt: number
  name: string
  email: string
  phone: string
  message: string
}

export interface IMessageContent {
  text?: string
  imageUrl?: string
  audioUrl?: string
  videoUrl?: string
  pdfUrl?: string
}

export interface IMessage {
  id: string
  channelId: string
  lessonId: string
  createdAt: number
  userId: string
  senderId: string
  senderName?: string
  content: IMessageContent
}

export interface IChannel {
  id: string
  lessonId: string
  userId: string
  entityId: string
  readBy: Record<string, number>
  unread: Record<string, number>
  lastMessage: IMessage
}

export type IPayanywaySig = {
  account: string
  transactionId: string
  subscriberId: string
  signature: string
  testMode: string
  currency: string
  amount: number
  lang: string
}

export type ILanguage = {
  id: string
  title: string
}

export type IWordCardTag = {
  id: string
  title: string
}

export type IWordCard = {
  id: string
  lang?: string
  word: string
  translate: string[]
  transcription?: string
  tags: string[]
  audio?: FileT
  definition?: string
  paragraphs?: ISyncTransalteParagraph[]
  createdAt: number
}
