import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  useDisclosure
} from '@chakra-ui/react'
import {
  Ref,
  ForwardRefRenderFunction,
  forwardRef,
  useImperativeHandle,
  useState,
  useContext,
  useMemo
} from 'react'
import get from 'lodash/get'
import LessonsContext from 'contexts/LessonsContext'
import { ILessonType } from 'shared/types'
import LectureSettings from 'modals/lesson/LectureSettings'
import TestSettings from 'modals/lesson/TestSettings'
import ExaminationSettings from 'modals/lesson/ExaminationSettings'
import DictionarySettings from 'modals/lesson/DictionarySettings'

export interface ILessonSettingsDrawer {
  open: (lessonId: string) => void
}
type Props = {}

const LessonSettingsDrawer: ForwardRefRenderFunction<
  ILessonSettingsDrawer,
  Props
> = (_props, ref: Ref<unknown>) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { lessons } = useContext(LessonsContext)
  const [lessonId, setLessonId] = useState<string>()

  const lesson = lessonId && get(lessons, lessonId)

  useImperativeHandle(ref, () => ({
    open: (lessonId: string) => {
      setLessonId(lessonId)
      onOpen()
    }
  }))

  const renderBody = () => {
    if (lesson) {
      if (lesson.type === ILessonType.TEST) {
        return <TestSettings l={lesson} />
      } else if (lesson.type === ILessonType.EXAMINATION) {
        return <ExaminationSettings l={lesson} />
      } else if (lesson.type === ILessonType.DICTIONARY) {
        return <DictionarySettings l={lesson} />
      } else {
        return <LectureSettings l={lesson} />
      }
    }
  }

  const title = useMemo(() => {
    if (lesson) {
      switch (lesson.type) {
        case ILessonType.TEST:
          return 'Настройки теста'
        case ILessonType.LECTURE:
          return 'Настройки лекции'
        case ILessonType.EXAMINATION:
          return 'Настройки экзамена'
        case ILessonType.DICTIONARY:
          return 'Настройки словаря'
      }
    }
  }, [lesson])

  return (
    <Drawer isOpen={isOpen} placement='right' onClose={onClose} size='lg'>
      <DrawerOverlay bg='transparent' />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader textStyle='small'>{title}</DrawerHeader>

        <DrawerBody pb={6}>{renderBody()}</DrawerBody>
      </DrawerContent>
    </Drawer>
  )
}

export default forwardRef(LessonSettingsDrawer)
