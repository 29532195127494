import { FC } from 'react'
import { ILesson } from 'shared/types'
import { dbUpdateLesson } from 'controllers/lessons'
import { VStack } from '@chakra-ui/react'
import SimpleInput from 'shared/components/SimpleInput'
import LanguageSelector from 'components/LanguageSelector'

type Props = {
  l: ILesson
}

const DictionarySettings: FC<Props> = ({ l }) => {
  const onChange = (upd: Partial<ILesson>) => {
    dbUpdateLesson(l.id, upd)
  }

  return (
    <VStack w='full' spacing={6} align={'start'}>
      <SimpleInput
        label='Название'
        value={l.name || ''}
        onChange={(v: string) => onChange({ name: v })}
      />
      <LanguageSelector
        value={l.dictionary?.lang}
        onChange={(v: string) => onChange({ dictionary: { lang: v } })}
      />
    </VStack>
  )
}

export default DictionarySettings
